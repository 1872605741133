export const experienceData = [
    {
        id: 1,
        company: 'Mahalaxmi Computer Center',
        jobtitle: 'Hardware and Networking',
        startYear: '2015',
        endYear: '2016'
    },
    {
        id: 2,
        company: 'Avid Exchange, Cloudfactory',
        jobtitle: 'Data Processing',
        startYear: '2019',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'Flutter Developer',
        jobtitle: 'Softwarica',
        startYear: '2021',
        endYear: 'Present'
    },
    {
        id: 4,
        company: 'Scrum Master',
        jobtitle: 'Softwarica',
        startYear: '2022',
        endYear: 'Present'
    },
]