export const socialsData = {
    github: 'https://github.com/bishalrajshrestha',
    facebook: 'https://www.facebook.com/bishalrajshrestha1',
    linkedIn: 'https://www.linkedin.com/in/bishalrajshrestha/',
    instagram: 'https://www.instagram.com/bishalrajshrestha',
    // codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/bishalrajshres2',
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    youtube: 'https://www.youtube.com/@bishalrajshrestha4908'
}