export const blogData = [
    {
        id: 1,
        title: '',
        description: '',
        date: '',
        image: '',
        url: ''
    },
    {
        id: 2,
        title: '',
        description: '',
        date: '',
        image: '',
        url: ''
    },
    {
        id: 3,
        title: '',
        description: '',
        date: '',
        image: '',
        url: ''
    },
    {
        id: 4,
        title: '',
        description: '',
        date: '',
        image: '',
        url: ''
    },
    {
        id: 5,
        title: '',
        description: '',
        date: '',
        image: '',
        url: ''
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/