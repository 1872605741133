import cs50 from "../assets/png/cs50.jpeg"
import excel from "../assets/png/excel.jpeg"
import flutter from "../assets/png/flutter.jpeg"

export const achievementData = {
    bio : "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
    achievements : [
        {
            id : 1,
            title : "CS50's Web Programming with Python and JavaScript",
            details : 'This course picks up where CS50 leaves off, diving more deeply into the design and implementation of web apps with Python, JavaScript, and SQL using frameworks like Django, React, and Bootstrap.',
            date : 'June 23, 2020',
            field : 'Web Development',
            image : cs50
        },
        {
            id : 2,
            title : 'Excel for Everyone: Core Foundation',
            details : 'Learn Excel fundamentals including data wrangling, spreadsheet management, and basic data analysis.',
            date : 'May 12, 2021',
            field : 'Excel',
            image : excel
        },
        {
            id : 3,
            title : 'Flutter Development Bootcamp with Dart',
            details : 'Udemy - Officially created in collaboration with the Google Flutter team.',
            date : 'Mar 29, 2021',
            field : 'App Development',
            image : flutter
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/