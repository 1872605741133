export const educationData = [
    {
        id: 1,
        institution: 'United Academy',
        course: 'Higher Secondary Education',
        startYear: '2007',
        endYear: '2015'
    },
    {
        id: 2,
        institution: 'MOLISS College',
        course: '+2',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'Softwarica College, Coventry, UK',
        course: 'Bsc.HONS Computer Science',
        startYear: '2019',
        endYear: '2022'
    },
]